<template>
  <div class="main_profile_wrapper">
    <h1 class="text-center">
      Personalize Profile coming soon
    </h1>
  </div>
</template>

<script>
export default {
  name: "PersonalizeProfile"
};
</script>

<style lang="scss" scoped>
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;
}
</style>
